<template>
  <table>
    <thead>
    <tr>
      <th class="center-align" style="width: 100px">ID</th>
      <th>Название</th>
      <th>Макро-регион</th>
      <th>Руководитель</th>
      <th>Старший инженер</th>
      <th>Инженер ИТО</th>
      <th>Старший инженер ИТО</th>
    </tr>
    </thead>

    <tbody>
    <tr v-for="(record, idx) of records" :key="record.id">
      <td class="center-align">
        <router-link :to="{path: '/clusters/' + record.id}"
                     v-tooltip="'Открыть'"
                     class="btn-small btn blue darken-4">
          {{record.id}}
        </router-link>
      </td>
      <td>{{record.name}}</td>
      <td>{{record.macro_region.name}}</td>
      <td>{{record.supervisor}}</td>
      <td>{{record.senior_engineer}}</td>
      <td>{{record.ito_engineer}}</td>
      <td>{{record.senior_ito_engineer}}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    records: {
      required: true,
      type: Array
    }
  }
}
</script>
